// @flow

import React, { Component } from 'react';
import classNames from 'classnames';
import PubNub from 'pubnub';
import ItemTile from './ItemTile';
import type { Item, ItemChange } from '../types';

type Props = {
  items: Array<Item>,
  pages: any,
  followedItems: Array<number>,
  isLoading: boolean,
  followItemToggle: (number) => void,
  updateItem: (ItemChange) => void,
};

const parseDate = (date: any): ?Date => {
  const newDate = new Date(date);
  return Number.isNaN(newDate.getTime()) ? null : newDate;
};

class ItemGrid extends Component<Props> {
  static defaultProps = {
    items: [],
    pages: {},
    followedItems: [],
    isLoading: false,
    followItemToggle: () => {},
    updateItem: () => {},
  };

  constructor() {
    super();

    this.pubnub = new PubNub({
      ssl: true,
      subscribeKey: 'sub-c-6cc22e6a-bb1c-11e6-963b-0619f8945a4f',
      suppressLeaveEvents: true,
      heartbeatInterval: 0,
    });

    this.pubnub.addListener(this.itemListener);
  }

  componentWillUnmount() {
    this.pubnub.removeListener(this.itemListener);
  }

  itemListener = {
    message: ({ channel, message }: { channel: string, message: Object }) => {
      const isItemMessage = /^item_\d+$/.test(channel);

      if (isItemMessage) {
        this.props.updateItem({
          id: parseInt(channel.split('_')[1], 10),
          aasmState: String(message.aasm_state),
          highBidAmount: Number(message.high_bid_amount),
          minimumBidAmount: Number(message.minimum_bid_amount),
          extended: Boolean(message.extended),
          saleEndsAt: parseDate(message.sale_ends_at),
        });
      }
    },
  }

  pubnub: Object;

  subscribe = (id: number): void => {
    this.pubnub.subscribe({
      channels: [`item_${id}`],
    });
  }

  unsubscribe = (id: number): void => {
    this.pubnub.unsubscribe({
      channels: [`item_${id}`],
    });
  }

  // TODO: Batch up these visibility changes so we can subscribe to multiple item channels
  // with a single call to pubnub.subscribe()
  handleVisibilityChange = (id: number): (visible: boolean) => void => {
    return (visible: boolean) => {
      if (visible) {
        this.subscribe(id);
      } else {
        this.unsubscribe(id);
      }
    };
  }

  renderEmptyState = (array_count: number, itemCount: number) => {
    let msg;
    if (itemCount === 0 && array_count === 0) {
      msg = <h2 className="well__title"><strong>Oh no!</strong> There aren’t any items matching your search right now.</h2>;
    } else {
      msg = <h2 className="well__title">Your search has returned too many results,<br />try narrowing down the list...</h2>;
    }

    return (
      <div className="well" style={{ width: '100%', textAlign: 'center' }}>
        { msg }
      </div>
    );
  }

  renderItems = () => {
    const {
      items,
      followedItems,
      followItemToggle,
    } = this.props;

    return items.map((item) => {
      return (
        <ItemTile
          item={item}
          key={item.id}
          isFollowing={followedItems.includes(item.id)}
          onFollowClick={followItemToggle}
          onVisibilityChange={this.handleVisibilityChange(item.id)}
        />
      );
    });
  }

  render() {
    const { items, isLoading, pages } = this.props;
    const isItemCountProblematic = (x) => x > 0 && x < 150000;
    const gridClasses = classNames(
      'items-grid',
      {
        'items-grid--is-loading': isLoading,
      }
    );

    return (
      <div>
        <div
          id="items_grid"
          className={gridClasses}
          role="region"
        >
          {isItemCountProblematic(items.length) ? this.renderItems() : this.renderEmptyState(items.length, pages.totalItems) }
        </div>
      </div>
    );
  }
}

export default ItemGrid;
