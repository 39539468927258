// @flow

import React, { useState, Dispatch } from 'react';
import CategorySelect from '~/admin/shared/components/CategorySelect';
// import SuggestedCategories from '~/admin/shared/components/SuggestedCategories';
// import AdditionalInformationLinksForm from '~/admin/shared/components/AdditionalInformationLinksForm';
// import AttributionHelperText from '~/admin/shared/components/AttributionHelperText';
import MultiCategorySelect from '~/admin/shared/components/MultiCategorySelect';
import { PendingItem, Category } from '~/shared/interfaces';

interface Props {
  item: PendingItem,
  updateItem: <Dispatch>(value: PendingItem) => void,
  suggestions?: boolean,
  additionalCategories?: boolean,
  onPropertyChange: (property: string) => (value: string) => void,
}

export const ItemCategorization: React.FC<Props> = ({
  item,
  updateItem,
  suggestions,
  additionalCategories,
  onPropertyChange,
}) => {
  const [suggestedCategory, setSuggestedCategory] = useState(null);

  const onCategoryChange = (newCategory: Category) => {
    if (!item) return;

    const { category } = item;

    if (category && !window.confirm(
      'Changing the category will delete existing attributes and will require you to re-attribute the item based on the new category. Do you want to continue?'
    )) { return; }
    updateItem({
      ...item,
      additionalCategories: newCategory ? item.additionalCategories : [],
      category: newCategory,
      properties: [],
    });
  };

  // const errorsFor = () => {};

  return (
    <>
      <label className="form-control__label">
        Category
      </label>

      {/*
       * Suggested Categories is currently disabled until determined if useful

      {suggestions &&
        <SuggestedCategories
          item={item}
          onChange={handleSuggestedCategorySelected}
        />
      }

      */}

      <CategorySelect
        // errors={this.errorsFor('primaryCategory')}
        value={item.category}
        onChange={onCategoryChange}
        isHighlighted={!! suggestedCategory}
      />

      {/*

      }
      {item.category && (
        <AttributionHelperText category={item.category} className="u-mb2" />
      )}
      */}

      {additionalCategories && (
        <>
          <label className="form-control__label">
            Additional Categories
            <span className="helper-text"> (Optional)</span>
          </label>
          <MultiCategorySelect
            value={item.additionalCategories}
            onChange={onPropertyChange('additionalCategories')}
            disabled={!item.category}
          />
        </>
      )}
    </>
  );
};

export default ItemCategorization;
