// @flow

import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import CategoryLink from './CategoryLink';
import { findSubTree } from '../utils/tree';
import type { Filter, Tree } from '../types';

type Props = {
  appliedParams: Object,
  filters: Array<Filter>,
  filter: Filter,
  keyword: ?string,
  selected: string,
};

class CategoryCrumbs extends PureComponent<Props> {
  renderCrumb = (category) => {
    return (
      <CategoryLink key={category.pathSlug} category={category} appliedParams={this.props.appliedParams} className="items-category-crumbs__crumb">
        {category.shortName}
      </CategoryLink>
    );
  }

  renderAllCategoriesCrumb = () => {
    if (this.props.selected) {
      return (
        <CategoryLink className="items-category-crumbs__crumb" appliedParams={this.props.appliedParams}>
          All Categories
        </CategoryLink>
      );
    } else {
      return (
        <span className="items-category-crumbs__crumb">All Categories</span>
      );
    }
  }

  renderTreeCrumbs = (tree: Array<Tree>) => {
    const { selected } = this.props;

    return (
      tree.map((leaf) => {
        return [
          this.renderCrumb(leaf),
          leaf.fullSlugPath !== selected && this.renderTreeCrumbs(leaf.children),
        ];
      })
    );
  }

  renderKeyword = () => {
    const { keyword } = this.props;

    if (keyword) {
      return (<span className="items-category-crumbs__crumb">“{keyword}”</span>);
    } else {
      return null;
    }
  }

  render() {
    const { filter, selected } = this.props;
    let categories = [];

    if (selected) {
      categories = findSubTree(filter.data, selected);
    }

    return (
      <nav className="items-category-crumbs" aria-label="Breadcrumbs">
        {this.renderAllCategoriesCrumb()}
        {this.renderTreeCrumbs(categories)}
        {this.renderKeyword()}
      </nav>
    );
  }
}

export default withRouter(CategoryCrumbs);
